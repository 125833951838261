<template>
  <div
    class="stage-card"
    :class="{
      'stage-card_live': live,
    }"
  >
    <div class="stage-card__live"></div>
    <div class="stage-card__container">
      <div class="stage-card__header">
        <div class="stage-card__header-left">
          <div
            class="stage-card__discipline stage-card__icon"
            :class="[disciplineCode && `stage-card__discipline_${disciplineCode}`]"
          >
            <Icon v-if="disciplineCode" :name="disciplineCode"></Icon>
            <b v-else>{{ title && title[0] }}</b>
          </div>
          <div class="stage-card__name">
            <b>{{ title || disciplineName }}</b>
            <div v-if="participant" class="text-sm mt-4">
              <Icon
                v-show="participant.gender !== null"
                class="icon"
                :name="participant.gender === Gender.Male ? 'male-color' : 'female-color'"
                size="s"
              />
              <span class="v-align-middle" :title="`${participant.lastName} ${participant.firstName}`">
                {{ participant.lastName }} {{ participant.firstName ? `${participant.firstName[0]}.` : '' }}
              </span>
            </div>
          </div>
        </div>
        <div class="stage-card__header-center" v-if="isDNF && !finished">
          <div class="text-with-icon text-nowrap">
            <Icon class="icon color-muted" name="leaderboard" size="s"></Icon>&nbsp;
            <span>
              <b>DNF</b>
            </span>
          </div>
        </div>
        <div class="stage-card__header-center" v-else-if="hasPosition">
          <div class="text-with-icon text-nowrap color-muted">
            <Icon class="icon" name="leaderboard" size="s"></Icon>&nbsp;
            <span>
              <template v-for="(item, i) in positions">
                <template v-if="i > 0 && positions.length > 1">  |  </template>
                <b :key="i">
                  <span class="color-text">{{ item.val || '--' }}</span> <span class="text-sm">{{ item.name }}</span>
                </b>
              </template>
            </span>
          </div>
        </div>
        <div class="stage-card__header-right">
          <div class="stage-card__icon" v-if="unreached && !isDNF">
            <Icon name="sand-clock"></Icon>
          </div>
          <div class="stage-card__result text-with-icon text-with-icon_flex" v-else>
            <Icon class="icon color-muted" :name="isAbsoluteTime ? 'gun' : 'stopwatch'" size="l"></Icon>&nbsp;&nbsp;
            <span><b>{{ result || '--' }}</b></span>
          </div>
        </div>
        <div class="stage-card__header-divider divider d-lg-none" v-if="hasPosition || isDNF"></div>
        <span></span>
      </div>
      <div class="stage-card__body" v-if="$slots.default">
        <slot></slot>
      </div>
      <div class="stage-card__footer" v-if="$slots.footer">
        <div class="stage-card__footer-container">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Gender } from '@/enums';
import { getDisciplineCode, getDisciplineName } from '@/utils';

export default {
  name: 'StageCard',
  props: {
    title: String,
    disciplineCode: String,
    live: Boolean,
    finished: Boolean,
    unreached: Boolean,
    position: [String, Number],
    positionByCategory: [String, Number],
    positionByGender: [String, Number],
    participant: Object,
    result: String,
    isRelay: Boolean,
    isDNF: Boolean,
    isAbsoluteTime: Boolean,
  },
  data() {
    return {
      Gender,
    };
  },
  computed: {
    disciplineName() {
      const code = getDisciplineCode(this.disciplineCode, 'stage');
      return getDisciplineName(code, this.$lang);
    },
    hasPosition() {
      return this.positions.length;
    },
    positions() {
      return [
        {
          name: `(${this.$t('result.position_cat')})`,
          val: this.positionByCategory,
          hidden: !this.positionByCategory,
        },
        {
          name: `(${this.$t('result.position_gen')})`,
          val: this.positionByGender,
          hidden: this.isRelay,
        },
        {
          name: `(${this.$t('result.position_abs')})`,
          val: this.position,
        },
      ].filter((o) => o.val > 0 && !o.hidden);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './StageCard';
</style>
